<template>
  <div class="register">
      <h1 class="title">申请入驻商户</h1>
      <div class="contract-modal-dialog">
        <ch-form :render-option="addOption" :model="modalForm"
                 :splice="[5,5,5]" ref="form" :rules="rules"
                 :props="{ labelWidth: '200px' , paddingRight: '80px'}"
        >
          <template v-slot:city>
            <el-cascader
                ref="city"
                :options="options"
                v-model="selectedOptions"
                :props="{ multiple: true}"
                collapse-tags
                class="w-260"
                size="small"
                @change="handleChange">
            </el-cascader>
          </template>

          <template v-slot:logo>
            <ch-upload-img
              action="/hm/general/upload"
              :img-length="1"
              :removeResponse="removeLogo"
              :uploadResponse="handleLogo"
              :fileList.sync="logoFileList"
              :loading.sync="isBtnLoading"
            ></ch-upload-img>
          </template>

          <template v-slot:upload>
            <ch-upload-img
              action="/hm/general/upload"
              :img-length="1"
              :removeResponse="removeUpload"
              :uploadResponse="handleUpload"
              :fileList.sync="uploadFileList"
              :loading.sync="isBtnLoading"
            ></ch-upload-img>
          </template>
        </ch-form>
      </div>
      <div class="flex_con_column footer">
        <el-checkbox v-model="isTreaty">
          我已阅读并同意
          <router-link to="userAgreement" target="_blank" style="color: #3e7eff">
            《用户服务协议》
          </router-link>
          <router-link to="disclaimerAgreement" target="_blank" style="color: #6495ff">
            《保证金协议规则》
          </router-link>
        </el-checkbox>
        <div class="flex_con" style="margin-top: 10px">
          <ch-button
                     @click="$router.go(-1)"
          >返回</ch-button>
          <ch-button type="ok" :loading="isBtnLoading"
                     @click="handleModal('form')"
          >立即提交</ch-button>
        </div>
      </div>
  </div>
</template>

<script>
import { bankCard, phone,USCC } from "@/utils/validator";
import { regionData } from 'element-china-area-data'
import {getServeTypeList} from "@/utils/utils"
export default {

  data(){
    var validatePass = (rule, value, callback) => {
      if (phone.test(value)) {
        this.isPhone = true
        callback();
      } else {
        this.isPhone = false
        callback(new Error('请输入正确手机号！'));
      }
    };
    return {
      modalForm: {
        companyName: "", // '公司名称'
        abbreviation: "", //'简称'
        address: "", // '详细地址',
        telephoneNumber: "", // '公司电话'
        creditCode: "", // '社会信用代码',
        mainUserName: "", //  '公司法人',
        personLiable: "", // '公司负责人',
        bankName: "", // '银行名称'
        bankNumber: "", // '银行号码'
        logo: "", //'公司logo图片地址',
        certificate:"", // '公司营业执照图片地址',
        code: null , // 验证码
        phonenumber: "", // 手机
        areaList:[],
      },
      type: '',
      title: '',
      isBtnLoading: false,
      isPhone: false,
      isTreaty: false, // 是否勾选用户协议
      logoFileList: [],
      uploadFileList: [],
      options: regionData,
      selectedOptions: [],
      typeList:[],
      rules: {
        certificate: [{ required: true, message: "请上传营业执照", trigger: ["blur", "change"] }],
        logo: [{ required: true, message: "请上公司logo", trigger: ["blur", "change"] }],
        name: [{ required: true, message: "请输入公司名称", trigger: ["blur", "change"] }],
        serverType: [{ required: true, message: "请选择服务类型", trigger: ["blur", "change"] }],
        legalPerson: [{ required: true, message: "请输入公司法人", trigger: ["blur", "change"] }],
        address: [{ required: true, message: "请输入公司地址", trigger: ["blur", "change"] }],
        openingBank: [{ required: true, message: "请输入开户行", trigger: ["blur", "change"] }],
        bankAccount: [{required: true, pattern: bankCard, message: "请输入正确银行账户", trigger: ["blur"] }],
        creditCode: [{required: true, pattern: USCC, message: "请输入正确的社会信用代码", trigger: ["blur"] }],
        leader: [{  required: true, message: "请输入负责人名称", trigger: ["blur", "change"] }],
        leaderPhone: [{required: true, validator: validatePass, message: "请输入正确手机号", trigger: ["blur"] }],
        areaList: [{  required: true, message: "请选择服务城市", trigger: ["blur", "change"] }],
      },
    }
  },
  computed: {
    addOption: function () {
      return [
        {type: 'slot',  label: '营业执照：', slotName: 'upload', prop: 'certificate'},
        {type: 'slot',  label: '公司logo：', slotName: 'logo', prop: 'logo'},
        {type: 'input', label: '公司名称：', prop: 'name', placeholder: '请输入公司名称' },
        {type: 'input', label: '公司简称：', prop: 'abbreviation', placeholder: '请输入公司简称' },
        {type: 'select', label: '服务类型：', prop: 'serverType', placeholder: '请选择服务类型',
          name: "name", value: "id", option: this.typeList},
        {type: 'input', label: '公司法人：', prop: 'legalPerson', placeholder: '请输入公司法人' },
        {type: 'input', label: '公司电话：', prop: 'companyTel', placeholder: '请输入公司电话' },
        {type: 'input', label: '公司地址：', prop: 'address', placeholder: '请输入公司地址' },
        {type: 'input', label: '开户行：', prop: 'openingBank', placeholder: '请输入开户行' },
        {type: 'input', label: '银行账户：', prop: 'bankAccount', placeholder: '请输入银行账户' },
        {type: 'input', label: '社会信用代码：', prop: 'creditCode', placeholder: '请输入社会信用代码' },
        {type: 'input', label: '负责人姓名：', prop: 'leader', placeholder: '请输入负责人' },
        {type: 'input', label: '负责人手机号：', prop: 'leaderPhone', placeholder: '请输入手机号' },
        {type: 'input', label: '负责人电子邮箱：', prop: 'leaderEmail', placeholder: '请输入电子邮箱' },
        {type: 'slot',  label: '服务城市：', slotName: 'city' ,prop: 'areaList',},

      ]
    },

  },
  watch: {
    'modalForm.responsibleUserId': function(newValue) {
      let that = this
      const responsible = that.userOption?.find(item => item.userId == newValue)
      if(responsible) {
        this.modalForm.responsibleName = responsible.nickName || ''
        this.modalForm.responsiblePhoneNumber = responsible.phonenumber || ''
        this.modalForm.responsibleEmail = responsible.email || ''
      }
    },
    'modalForm.serviceUserId': function(newValue) {
      let that = this
      const service = that.userOption?.find(item => item.userId == newValue)
      if(service) {
        this.modalForm.serviceJobNumber = (service.userName + "-" + service.nickName) || ''
        this.modalForm.servicePhoneNumber = service.phonenumber || ''
      }
    },
  },
  mounted() {
    getServeTypeList().then((res) => {
      this.typeList = res
    })
  },
  methods: {
    // 打开prompt提示
    // 发送验证码
    sendAuthCode() {
      if(this.isPhone) {
        this.$curl.get('/system/merchantRegistration/judgePhone/' + this.modalForm.phonenumber)
          .then((res) => {
            if(res.data == 1) {
              this.$message.error('该手机号已被注册');
            } else {
              this.$curl.get('/system/merchantRegistration/getCode/' + this.modalForm.phonenumber)
                .then(() => {
                  this.$message.success('验证码已发送');
                })
            }
          })
      }
    },
    openAdd() {
      this.$nextTick(() => {
        this.$refs?.form?.resetForm()
      })
      this.type = 'add'
      this.modalForm = {}
      this.title = '商户注册'
      this.modalForm = {
        companyName: "", // '公司名称'
        abbreviation: "", //'简称'
        address: "", // '详细地址',
        telephoneNumber: "", // '公司电话'
        creditCode: "", // '社会信用代码',
        mainUserName: "", //  '公司法人',
        personLiable: "", // '公司负责人',
        bankName: "", // '银行名称'
        bankNumber: "", // '银行号码'
        province: "", // '省'
        city: "", // '市'
        county: "", // '县'
        logo: "", //'公司logo图片地址',
        certificate:"", // '公司营业执照图片地址',
        phonenumber: "", // 手机
      }
    },
    // 执行确定
    handleModal(formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          if(!this.isTreaty) {
            this.$message.warning("请勾选协议")
            return false
          }
          this.isBtnLoading = true
          this.$curl.post("/hm/company/register", this.modalForm)
            .then(() => {
                this.isBtnLoading = false
                this.$message.success("提交成功！");
                this.$chprompt.showPrompt({
                  title: '注册信息提交成功',
                  text: '请等待平台审核，通过后会有客服与您联系！'
                })
                this.closeModal()
              }).finally(() => {this.isBtnLoading = false})
        }
      })
    },
    // 关闭前清除数据
    closeModal() {
      this.modalForm = {}
      this.uploadFileList = []
      this.logoFileList = []
    },
    // 上传营业执照
    handleUpload(res) {
      this.modalForm.certificate = res.data.url
      this.$refs.form.clearValidate('certificate')
    },
    // 上传logo 图片
    handleLogo(res) {
      this.modalForm.logo = res.data.url
      this.$refs.form.clearValidate('logo')
    },
    // 删除logo
    removeLogo() {
      this.modalForm.logo = ''
    },
    // 删除营业执照
    removeUpload() {
      this.modalForm.certificate = ''
    },
    // 选择城市
    handleChange (value) {
      this.modalForm.areaIdsJson = JSON.stringify(value)
      let checkedNodeList = this.$refs.city.getCheckedNodes();
      checkedNodeList = checkedNodeList.filter(item => !(item.parent && item.parent.checked))
      this.modalForm.areaList = checkedNodeList.map(item => {
        let arr = []
        if (item.level === 1 && item.children[0].label !== '市辖区') {
          item.children.forEach(v => {
            arr.push({
              districtName: v.label,
              cityName: item.label,
            })
          })
          return {
            ...arr
          }
        } else if (item.level === 1 && item.children[0].label === '市辖区') {
          return {
            districtName: item.label,
            cityName: item.label,
          }
        }
        if (item.level === 2) {
          return {
            districtName: '',
            cityName: item.label,
            provinceName: item.parent.label,
          }
        }
        if (item.level === 3 && item.parent.label !== '市辖区') {
          return {
            provinceName: item.pathLabels[0],
            cityName: item.pathLabels[1],
            districtName: item.pathLabels[2],
          }
        } else if (item.level === 3 && item.parent.label === '市辖区') {
          return {
            districtName: item.label,
            cityName: item.parent.parent.label,
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.register{
  height: 100vh;
  position: relative;
}
.title{
  text-align: center;
  padding: 5% 0;
}
.contract-modal-dialog {
  overflow: auto;
  .phone {
    display: flex;
  }
  .modal-file {
    display: flex;
    justify-content: space-between;
    &>input {
      max-width: 200px;
    }
  }
  // 上传组件样式
  .upload-img-wrapper{
    .el-upload {
      width: 80px;
      height: 80px;
    }
    // 上传图片样式
    .el-upload-list__item {
      width: 80px;
      height: 80px;
      margin-bottom: 0;
    }
  }
}
.footer{
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

::v-deep {
  .el-dialog {
    display: flex;
    flex-direction: column;
    height: 90vh;
  }
  .el-dialog__header {
    height: 8vh;
    .el-dialog__title {
      font-size: 26px;
    }
  }
  .el-dialog__body {
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
  }
  .el-dialog__footer {
    height: 12vh;
  }
  .el-dialog__wrapper {
    background-color: #F5F6F9;
  }
  .jsx_form_wrapper .form_splice_wrapper{
    justify-content: center;
  }
  .el-cascader__tags{
    width: 100%;
  }
}

</style>